let utils = {}

utils.setStorage = (item, value) => {
  localStorage.setItem(item, JSON.stringify(value))
}

utils.getStorage = (item) => {
  if(localStorage.getItem(item)) return localStorage.getItem(item) ? JSON.parse(localStorage.getItem(item)) : {}
}

utils.clearStorage = () => {
  localStorage.clear()
}

export default utils