<script>
export default {
  name: "AdminMenu",
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    menu: {
      type:Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultActive: '首页'
    }
  },
  watch: {
    $route: {
     handler(val) {
       this.defaultActive = val.name
     },
      immediate: true,
      deep: true
    }
  }
};
</script>

<template>
  <div>
    <div :style="{width: !isCollapse ? '200px' : '64px'}"></div>
    <el-aside :width="!isCollapse ? '200px' : '64px'" class="aside" >
      <el-menu
        :collapse="isCollapse"
        class="aside-menu"
        background-color="#032121"
        text-color="#fff"
        :default-active="defaultActive"
      >
        <template v-for="(menuItem,menuIndex) in menu[0].children">
          <el-menu-item @click="$router.push({ name: menuItem.name })"  :index="menuItem.name"  :key="menuIndex">
            <i :class="'el-icon-' + menuItem.meta.icon"></i>
            <span slot="title">{{menuItem.name}}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
  </div>
</template>

<style scoped lang="scss">
@import "index.scss";
</style>