// /app/member/photoUpload
import { get, post } from "@/utils/request";

// 获取用户信息
export async function getUserInfo(){
  return get("/api/member/info");
}

// 修改用户信息
export async function setUserInfo(data){
  return post("/api/member/saveInfo", data);
}

// 培训记录
export function getTrainRecord(){
  return get("/api/study/training/record/data")
}

// 获取验证码
export function getCode(data){
  return get("/api/code", data);
}

export function getMemberCode(data){
  return get("/api/member/confirm/code", data);
}


// 修改手机号码
export function savePhone(data){
  return post("/api/member/savePhone", data)
}

// 手机号登录
export function phoneLogin(data){
  return post("/api/login", data, null, { isMessage202: true });
}

// 手机号登录
export function saveLogout(data){
  return post("/api/logout", data, null, { isMessage202: true });
}

// 注册
export function regist(data){
  return post("/api/register/phone", data, null, { isMessage202: true });
}

// 统计
export function statistic(data){
  return get("/api/member/statistic", data);
}

// 统计
export function dictData(data){
  return get("/api/common/dictData", data);
}

// 组织
export function companySwitch(data){
  return post("/api/member/company/switch", data);
}



