import utils from "@/utils/utils";

export const requestConfig = (config) => {
  config.headers = {
    'Content-Type': config.json !== undefined ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded;charset=UTF-8',
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
    token: utils.getStorage('TOKEN')
  }
  // config.data = Qs.stringify(config.data)

  return config
}


export const response202 = (response) => {
  return response
}
